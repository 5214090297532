
.pagewrapper h1{
    /*font-size: 16px;*/
    /*text-align: center;*/

}
.pagewrapper_header{
    /* background-color: red; */
 
    width: 200px;
    margin: 0 auto;
    /*height: 100px;*/
 
}

.bandpanel{
    border: 1px solid white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 27px;
}

.bandpanel_product{
    width: 150px;
    height: 110px;
    margin-top: 14px;
    margin-bottom: 12px;
}
