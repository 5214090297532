.content-img {
  
    margin:  0 auto;
    height: 150px;
    margin-top: 50px;
}
.content{
    padding: 0 1em;
  
}

.endpage-content h1{
    font-size: 2em;
}
.endpage-content p{
    font-size: 1.5em;
    text-align: center;
}