body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .pagewrapper{
 
} */
.pagewrapper h1 {
    /*font-size: 16px;*/
    /*text-align: center;*/

}

img {
    width: 100%;
    /*height: 100%;*/
}

.pagewrapper {
    width: 375px;
    background: #f5f5f5;
    margin: 0 auto;
    height: 100vh;
    text-align: center;
}


.main-btn {
    background-color: red;
    color: white;
    outline: none;
    border-radius: 29px;
    border: 1px solid transparent;
    padding: 10px 0;
    font-size: 19px;
    cursor: pointer;
    text-align: center;
    width: 89%;
    margin-top: 10px;
}

.flex-column {
    display: flex;
    flex-direction: column;
    text-align: start;
}


.btn {
    border-radius: 30px !important;
    color: #fff !important;
}
