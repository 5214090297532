.content h1{
    font-size: 21px;
    margin-top: 44px;
}
.content p{
    font-size: 15px;
    margin-top: 44px;
}
.content span{
    font-size: 35px;
}
.content h2{
    font-size: 112px;
    margin: 0;
    margin-bottom:40px
}
.content{
    margin-top: 10px;
}